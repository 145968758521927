import React from "react";
import classnames from "classnames";
import Layout from "../components/Layout";
import ExternalLink from "../components/ExternalLink";

const PROJECTS = [
  {
    id: "hat-game",
    title: "The Hat Game",
    body: (
      <p>
        With the Coronavirus pandemic still in full swing, this online version
        of{" "}
        <ExternalLink href="https://en.wikipedia.org/wiki/Celebrity_(game)">
          The Hat Game (aka Celebrity)
        </ExternalLink>{" "}
        is great for your next Zoom call with loved ones. Remember to keep track
        of which names have been drawn - each round is tougher than the last!
      </p>
    ),
    link: "https://thefred.space/play/the-hat-game/",
    linkText: "Play now",
    className: "bg-green",
  },
  {
    id: "covid-coworking",
    title: "COVID Coworking",
    body: (
      <p>
        Missing the face-to-face experience of working in an office? Chat with
        coworkers while you explore this scale replica of{" "}
        <ExternalLink href="https://yobota.com">Yobota</ExternalLink>
        &apos;s Old Street office. If you&apos;re feeling adventurous, try
        hacking your way into the server room.
      </p>
    ),
    link: "https://thefred.space/play/covid-coworking/",
    linkText: "Jump in",
    className: "bg-grey",
  },
  {
    id: "quavr",
    title: "quavr",
    body: (
      <p>
        quavr is a music library app enabling you to play and organise your
        entire digital collection from your web browser. Available for Windows,
        macOS, Linux and ChromeOS through the Chrome Web Store.
      </p>
    ),
    link:
      "https://chrome.google.com/webstore/detail/quavr/jmbfkccejfcbfblicgkapoaijboknigo",
    linkText: "Check it out",
    className: "bg-orange",
  },
];

const ProjectsPage = () => {
  return (
    <Layout>
      <div className="stack-m">
        <h1>Show me what you got</h1>
        {PROJECTS.map(({ id, title, body, link, linkText, className }) => (
          <article
            key={id}
            aria-labelledby={id}
            className={classnames("relative vpadding-m hpadding-m", className)}
          >
            <div className="">
              <div className={classnames("triangle", className)}></div>
              <h2 id={id}>{title}</h2>
              {body}
              <ExternalLink href={link}>{linkText}</ExternalLink>
              {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"> */}
              {/*   <polygon fill="white" points="0,100 100, 0 100,100" /> */}
              {/* </svg> */}
            </div>
          </article>
        ))}
      </div>
    </Layout>
  );
};

export default ProjectsPage;
